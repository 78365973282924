import React from "react";
import Button from "../Button/index";

import {Link} from 'react-router-dom'

import logo from "../../Images/sunai-navbar-logo.svg";

import "./style.scss";

interface NavbarProps {
  serviceHandler: Function;
  techHandler: Function;
  referenceNav: any;
}


function Navbar({serviceHandler,techHandler,referenceNav}: NavbarProps) {

  const widthb = window.innerWidth;

  return (
    <div className="container-fluid navbar" ref={referenceNav}>
      <Link to ="/">
        <div className="navbar__sunai-logo">
          <img width={widthb < 1024 && widthb >=992 ? '170px' : widthb <= 992 && widthb > 452 ? '140px' : widthb > 292 && widthb <=452 ? '120px' :widthb <=292 ? '70px' : '198px' } src={logo} alt="LOGO" />
        </div>
          </Link>

      
      <div className="navbar__btns">
        <div className="navbar__btns-links">
          <div className="navbar__link-text" onClick={() => serviceHandler()}>Services</div>
          <div className="navbar__link-text" onClick={() => techHandler()}>Technologies</div>
        </div>
        <div className="navbar__contact-btn">
          <Link to ="/contact-page">
          <Button buttonText="Contact us" />
          </Link>
        </div>
      </div>
    </div>
  );
}

export default Navbar;
