import React, { MutableRefObject } from "react";

import logo1 from "../../Images/illustration-web-development.svg";
import logo2 from "../../Images/illustration-cloud-devops.svg";
import logo3 from "../../Images/illustration-infrastructure-network.svg";
import logo6 from "../../Images/illustration-product-design.svg";
import logo4 from "../../Images/illustration-mobile-app-development.png"
import logo5 from "../../Images/illustration-digital-marketing.png"

import "./style.scss";

interface ServiceProps {
  reference: any;
}

function Section2({reference}: ServiceProps) {
  return (
    <div className="container-fluid section2" ref={reference}>
      <div style={{fontWeight:600}} className="text-md text-center mb-4">
        Our Services
      </div>

      <div className="section2__icons">
        <div className="section2__icon">
          <div className="img1">
            <img src={logo1} alt="logo" />
          </div>
          <div style={{fontWeight:600}} className="text-vsm mt-2 text-center">
            Web App Development
          </div>
        </div>

        <div className="section2__icon">
          <div className="img2">
            <img src={logo4} alt="logo" />
          </div>
          <div style={{fontWeight:600}} className="text-vsm mt-2 text-center">
            Mobile App Development
          </div>
        </div>

        <div className="section2__icon">
          <div className="img3">
            <img src={logo6} alt="logo" />
          </div>
          <div style={{fontWeight:600}} className="text-vsm mt-2 text-center">
            Product Design
          </div>
        </div>

        <div className="section2__icon">
          <div className="img2">
            <img src={logo2} alt="logo" />
          </div>
          <div style={{fontWeight:600}} className="text-vsm mt-2 text-center">
           Cloud & DevOps Automation
          </div>
        </div>

        <div className="section2__icon">
          <div className="img5">
            <img src={logo3} alt="logo" />
          </div>
          <div style={{fontWeight:600}} className="text-vsm mt-2 text-center">
            Infrastructure & Network
          </div>
        </div>

        

        <div className="section2__icon">
          <div className="img5">
            <img src={logo5} alt="logo" />
          </div>
          <div style={{fontWeight:600}} className="text-vsm mt-2 text-center">
            Digital Marketing
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default Section2;
