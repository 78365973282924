import React from "react";
import Button from "../Button";

import {Link} from 'react-router-dom'

import "./style.scss";


function Section4() {
  return (
    <div className="container-fluid section4">
      <div style={{fontWeight:600}} className="text-sm section4__text mb-3">
        Ready to get Started with your product ?
      </div>
      <Link to ="/contact-page">
      <Button style={{fontWeight:600}} buttonText="Contact us" variant="secondary" />
      </Link>
    </div>
  );
}

export default Section4;
