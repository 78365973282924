
import React,{MutableRefObject, useEffect, useRef} from "react";

import LandingPage from "./LandingPage";
import ContactPage from './ContactPage'
import Error from './components/Error'
import Navbar from "./components/Navbar/index";
import Footer from "./components/Footer/index";

import { BrowserRouter as Router, Routes,Route,useMatch,useNavigate } from "react-router-dom";

import "./App.css";




function App() {
  const reference: any = useRef();
  const reference1: any =useRef();
  const referenceNav: any = useRef();
 

  const onClickServices = () => {
    if(!reference?.current){
      localStorage.setItem('navigate','services');
      window.location.href = "/";
    }
    window.scrollTo(0, reference.current?.offsetTop);
  };

  const onClickTechnology=()=>{
    if(!reference?.current){
      localStorage.setItem('navigate','technologies');
      window.location.href = "/";
    }
    window.scrollTo(0,reference1.current?.offsetTop)
  }

  const onMessage = () => {
    window.scrollTo(0, referenceNav.current?.offsetTop);
  }

  useEffect(() => {
    if(localStorage.getItem('navigate') == 'services' && reference?.current){
      onClickServices();
      localStorage.removeItem('navigate');
    }else if(localStorage.getItem('navigate') == 'technologies' && reference1?.current){
      onClickTechnology();
      localStorage.removeItem('navigate');
    }

  },[localStorage.getItem('navigate'),reference,reference1])


  
  return (
    <Router>

      <Navbar serviceHandler= {onClickServices} techHandler={onClickTechnology} referenceNav={referenceNav}/>

      <Routes>
        <Route path="/" element={<LandingPage reference={reference} reference1={ reference1}/>}/>
        <Route path="/contact-page" element={<ContactPage  onMessage = {onMessage} />} />
        <Route path="*" element={<Error/>}/>
      </Routes>
      <Footer serviceHandler= {onClickServices} techHandler={onClickTechnology}/>
    </Router>
  );
}

export default App;
