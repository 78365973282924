import React from 'react'

import Button from "../Button/index";

import {Link} from 'react-router-dom'

import logo from "../../Images/404-illustration.svg";
import logoMobile from "../../Images/404-illustration-mobile.svg"

import './style.scss'

function index() {
    const width = window.innerWidth;
    return (
        <div>

            <div style={{marginTop:60, textAlign:"center"}}>
                <img src={width < 451 ? logoMobile : logo} alt="LOGO" className='img'/>
            </div>

            <div style={{marginTop:50, marginBottom:30}}>
            <div className="text-md mt-2 font-weight-bold text-center">
            The page you requested could not be found
            </div>
            </div>

            <div style={{marginBottom:40}}> 
            <div className='text-center'>
            <Link to ="/">
            <Button buttonText="Go back to homepage" />
            </Link>
            </div>
            </div>
            
            
        </div>
    )
}

export default index
