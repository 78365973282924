import React, {MouseEventHandler} from "react";

import "./style.scss";

interface props {
  variant?: string;
  buttonText?: string;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  className?: string;
  style?: any;
} 

function Button({ variant = "primary", buttonText, onClick, className }: props) {
  const btnClass = variant === "primary" ? "primary" : "secondary";

  return (
    <button
      className={`default-button ${btnClass} ${className || ""}`}
      onClick={onClick}
    >
      {buttonText}
    </button>
  );
}

export default Button;
