
import React,{MutableRefObject, useEffect} from 'react'

import Section1 from "./components/Section1";
import Section2 from "./components/Section2";
import Section3 from "./components/Section3";
import Section4 from "./components/Section4";
import SectionHero from "./components/SectionHero";

import "./App.css";

interface LandingPageProps {
  reference: any;
  reference1: any;
}

function LandingPage({reference, reference1}: LandingPageProps) {

  useEffect(() => {
    window.scroll(0,0)
  }, [])
  return (
    <div className='container-fluid p-0'>
      
      <SectionHero />
      <Section1 />
      <Section2 reference={reference} />
      <Section3 reference1={reference1}/>
      <Section4 />
      
    </div>
  );
}

export default LandingPage;
