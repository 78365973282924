import React from "react";
import {Link} from "react-router-dom"
import logo from "../../Images/sunai-navbar-logo.svg";
import logo1 from "../../Images/bx_bxl-linkedin-square.png"
import logo2 from "../../Images/bx_bxl-facebook-circle.png"
import logo3 from "../../Images/bx_bxl-twitter.png"
import logo4 from "../../Images/bx_bxl-youtube.png"
import logo5 from "../../Images/bx_bxl-instagram.png"


import "./style.scss";

interface FooterProps {
  serviceHandler: Function;
  techHandler: Function;
}

function Footer({serviceHandler,techHandler}: FooterProps) {
  const widthb = window.innerWidth;
  //const style1={marginTop:600}
  //const style2={marginTop:0}
  return (
    <div className="container-fluid p-0">
    <div  className="footer" >
      <div className="footer__section1">
        <div className="footer__image">
          <Link to="/"><img src={logo} alt="LOGO"></img></Link>
          <br/>
        </div>

        <div className="footer__texts">
          <div className="footer__links">
            <div className="footer__link-section">
              <div className="footer__link" onClick={() => serviceHandler()}>Services</div>
              <div className="footer__link"onClick={() => techHandler()}>Technology</div>
            </div>
            <div className="footer__link-section">
              {/* <div className="footer__link">About</div>
              <div className="footer__link">Blog</div> */}
              <div className="footer__link" ><Link to="contact-page" style={{ textDecoration: 'none', color:"black" }}>
              <div className='footer__link__contact'>Contact</div></Link>
              </div>
    
            </div>
          </div>
        </div>
      </div>
      <div className="social-media-icons">
          <a target="_blank" href="https://www.linkedin.com/company/sun-ai/"><img style={{marginRight:12,cursor:"pointer"}} src={logo1} alt="LOGO"></img>
            </a> 
          
         <a target="_blank" href="https://www.facebook.com/SUNAITECHNOLOGY/"> <img style={{marginRight:12, cursor:"pointer"}} src={logo2} alt="LOGO"></img></a>
          <img style={{marginRight:12}} src={logo3} alt="LOGO"></img>
          <img style={{marginRight:12}} src={logo4} alt="LOGO"></img>
         <a target="_blank" href="https://www.instagram.com/sunai_technology/"> <img style={{marginRight:12}} src={logo5} alt="LOGO"></img></a>
          </div>

      <div className="small-texts">
        <div><i className="far fa-copyright" ></i> 2022 Sunai</div>
      <div className="small-texts-1">
        <div>Terms Of Service</div>
        <div>Privacy Policy</div>
      </div>
      </div>
    </div>
    </div>
    
  );
}

export default Footer;