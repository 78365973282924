
import React,{useEffect} from "react";

import Forms from "./components/Forms";
import RemainingTexts from "./components/RemainingTexts";

interface ContactPageProps{
  onMessage: Function;
}

function ContactPage({onMessage}:ContactPageProps) {
  useEffect(() => {
    
    window.scroll(0,0)
  }, [])
  return (
    <div className="container-fluid p-0 contact-page">
            <Forms onMessage= {onMessage}/>
            {/* {window.innerWidth <= 1024 && 
            <RemainingTexts/>
           } */}
            
    </div>
  );
}

export default ContactPage;
