import React from "react";
import "./style.scss";
import logo from "../../Images/ph.png";



function RemainingTexts() {
  return (
    <div className='remaining-texts'>
      <div className='remaining-texts__1'>

        <div style={{fontWeight:600}} className='text-sm'>
          Or, email us at:</div>
        <div style={{fontWeight:400}} text-vsm text-center>
          
        <a target="_blank" href="mailto:connect@sunai.xyz">connect@sunai.xyz</a></div>

      </div>
      <br/>
      <div className='remaining-texts__1'>
      <div style={{fontWeight:600}} className='text-sm'>
        You can also find us at one of our office locations.</div>
        <div className="two-locations">
        
        <div className="two-locations-1">
          <div style={{fontWeight:600}} className="two-locations-1-1">San Fransisco, California</div>
          <div style={{fontWeight:400}} className="two-locations-1-2"><i className="fas fa-map-marker-alt"></i> Emeryville
5900 Hollis St, Ste W, Emeryville, CA 94608</div>
          <div style={{fontWeight:400}} className="two-locations-1-3"><a href="tel:+14157020235"><img src={logo} alt="LOGO" /> +1 (415) 702-0235</a></div>
        </div>
        
        <div className="two-locations-2">
          <div style={{fontWeight:600}} className="two-locations-2-1">Kathmandu, Nepal</div>
          <div style={{fontWeight:400}} className="two-locations-2-2"><a target="_blank" href="https://goo.gl/maps/s4st3XZBPKgVdUYb7"><i className="fas fa-map-marker-alt"></i> Gairidhara, Kathmandu - 2
Bagmati, Nepal, 44600</a></div>
          <div style={{fontWeight:400}} className="two-locations-2-3"><a href="tel:+9779808772390"><img src={logo} alt="LOGO" /> +977 9808772390</a></div>
          <div style={{fontWeight:400}} className="two-locations-2-3"><a href="tel:+9779808105548"><img src={logo} alt="LOGO" /> +977 9808105548</a></div>
        </div> 
        </div>
        
        
      </div>
    </div>

  );
}

export default RemainingTexts;
