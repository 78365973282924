import React from "react";
import {Link} from 'react-router-dom'

import logo from "../../Images/hero-section-illustration.svg";
import Button from "../Button";
import "./style.scss";

function SectionHero() {
  return (
    <div className="container-fluid section-hero">
      <div className="section-hero__section1">

        <div className="text-lg mb-3 section-hero__texts">

          Transform your ideas into an innovative product
        </div>
        <div style={{fontWeight:400}}className="text-vsm mb-3 section-hero__texts">
          Sunai is a dynamic team of software professionals helping you digitize
          your business and bring your ideas to life. We build strategies to
          deliver solutions that achieve tangible outcomes.
        </div>
        <Link to ="/contact-page">
        <Button
          buttonText={`Let's get started`}
          className={"my-3 section-hero__button"}
        />
       
          </Link>
        
      </div>
      <div className="section-hero__section2">
        <img src={logo} alt="LOGO" />
      </div>
    </div>
  );
}

export default SectionHero;
