import React from "react";
import logo from "../../Images/Technologies.png";
import logoMobile from "../../Images/Technologies-mobile.png";
import "./style.scss";

interface TechProps {
  reference1: any;
}

function Section3({reference1}:TechProps) {
  const width = window.innerWidth;

  return (
    <div className="container-fluid section3" ref={reference1}>
      <div className="section3__image">
        <img src={width < 451 ? logoMobile : logo} alt="LOGO" />
      </div>
      <div className="section3__text" >
        <div style={{fontWeight:600}} className="text-md text-center">
          Technologies we work with
        </div>
        <div className="text-vsm text-center">
        We have experts in technologies and stacks such as React, Angular, Java, Python, NodeJS, AWS, Azure, Oracle, PostgreSQL, MongoDB, and many more.
        </div>
      </div>
    </div>
  );
}

export default Section3;
