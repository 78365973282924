import React from "react";
import "./style.scss";

function Section1() {
  return (
    <div className="container-fluid section1">
      <div style={{fontWeight:600}}className="text-md py-2 text-center">
        Get your business off to a great start
      </div>
      <div className="text-vsm text-center">
        We consult teams of any size, anywhere on the world, and help them
        realize technical solutions for their ideas and products.
      </div>
    </div>
  );
}

export default Section1;
