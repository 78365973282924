
import React,{ useRef, useState} from 'react'
import axios from 'axios'
import {useNavigate} from 'react-router-dom'
import {validEmail } from './regex'
import Button from "../Button/index";
import './style.scss'
import RemainingTexts from '../RemainingTexts';
import ReCAPTCHA from 'react-google-recaptcha';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

interface FormProps{
  onMessage: Function;
}

function Forms({onMessage}:FormProps) {

  const widthb = window.innerWidth;
  const style1={marginBottom:0}
  const style2={marginBottom:50}
  const style3:any={color:"green",marginTop:10,width:300,background:"lightgreen", textAlign:"center", text:"bold"}
  const style4:any={color:"green",marginTop:10,width:450,background:"lightgreen", textAlign:"center", text:"bold"}

  let navigate=useNavigate()

  const secretKey = process.env.REACT_APP_RECAPTCHA_SECRET_KEY as string; 
  let reRef=useRef<ReCAPTCHA > () as React.MutableRefObject<ReCAPTCHA> ;
  
  const[values,setValues]=useState({
    name:"",
    company:" ",
    email:"",
    phoneNumber:" ",
    hearAboutUs:"",
    budget:"",
    reasonForContact:'',
    token:" "

  });

  const[submitted,setSubmitted]=useState(false);
  const[valid, setValid]=useState(false);
  const[validmail,setValidmail]=useState(true)
  




  const handleNameChange=(event: any)=>{

    setValues({...values, name:event.target.value})

  }

  const handleEmailChange=(event: any)=>{
    setValues({...values, email:event.target.value})
  }

  const handleCompanyChange=(event: any)=>{

    setValues({...values,company:event.target.value})
  }

  const handlePhoneChange=(event:any)=>{
    setValues({...values, phoneNumber
:event.target.value})

  }

  const hearAboutUschangeHandler=(event:any)=>{
    setValues({...values,hearAboutUs:event.target.value})
  }
  
  const handleSubmit= async (event: any)=>{
    // console.log(grecaptcha.getResponse().length)
    if(grecaptcha.getResponse().length !== 0){

    event.preventDefault();
    if(values.name &&validEmail.test(values.email)&& values.phoneNumber&&values.company&&values.email&&values.hearAboutUs&&values.reasonForContact&&values.budget)
    {
      setValid(true)
    const token = reRef.current.getValue();
    onMessage();
    setSubmitted(true)
    await axios
    .post('https://y0y69mj35l.execute-api.us-east-1.amazonaws.com/prod/api/contacts',{...values, token:token})

      .then(response =>{
        console.log('resp is' +response)
        setTimeout(() => {
          navigate('/')
        }, 500)

      })
      .catch(error=>{
        console.log(error)
        console.log('sorryyyy')
        setSubmitted(false)
      })

      reRef.current.reset();
    }
    else{
      setSubmitted(false)
      setValidmail(false)
     }
    }
    else{
      event.preventDefault();
      
      toast("Please Verify Captcha");

    }
  }
  const changeHandler=(event:any)=>{
    setValues({...values,reasonForContact:event.target.value})
  }
  const changeHandler2=(event:any)=>{
    setValues({...values,budget:event.target.value})
  }

  return (
    <>
    {submitted && valid ?<div  className="success-message">✓ Your message has been sent.</div>:null}
    {!submitted && valid ?<div  className="failure-message"><i className="fa fa-times" aria-hidden="true"></i>  Error sending message</div>:null}
    <div className='container-fluid whole-forms'>
      <div className='container-fluid row m-0 p-0'>
      <div className='col-lg-6 col-md-12 col-sm-12 p-0'>
       
      <div className='forms__text-1' >
        <div className='text-md' style={{marginTop:80, fontWeight:600}}>
          What would you like to build ?
      </div>
      </div>

      <div className='forms__text-2'>
      <div className='text-sm '>
        Leave us a message and we will get back to you as soon as possible
      </div>
      </div>


      <form className="form" onSubmit={handleSubmit}>

        <div style={{fontWeight:500}} className='text-vsm'>Your name *</div>
        <label className='col-12 form_label'>
          
        <input 
        type="text"

        name="name"
        value={values.name} 

        className='col-sm-12 col-form-label col-form-label-lg'
        onChange={handleNameChange}
        required/> 
        </label>
        <br/>

        {submitted && !values.name?<span style={{color:'red'}}>Please enter your name</span>:null}

   
         <div style={{fontWeight:500}} className='text-vsm'>Your company </div>
         <label className='col-12 form_label'>
        <input  type="text"

        name="company"
        value={values.company} 

        className='col-sm-12 col-form-label col-form-label-lg'
        onChange={handleCompanyChange}/> 
        </label>
        <br/>

        {submitted && !values.company?<span style={{color:'red'}}>Please enter company name</span>:null}


         <div style={{fontWeight:500}} className='text-vsm'>Email *</div>
         <label className='col-12 form_label'>
        <input type="text" 
        name="email"
        value={values.email} 
        className='col-sm-12 col-form-label col-form-label-lg'
        onChange={handleEmailChange}
        required/> 
        </label>
        <br/>
        {!validmail ?<span style={{color:'red'}}>Please enter a valid Email</span>:null}


         <div style={{fontWeight:500}} className='text-vsm'>Phone number </div>
         <label className='col-12 form_label'>
        <input  
        type="text"

        name="phoneNumber
  "
        value={values.phoneNumber
  } 

        className='col-sm-12 col-form-label col-form-label-lg'
        onChange={handlePhoneChange}/> 
        </label>
        <br/>

        {submitted &&!values.phoneNumber ?
        <span style={{color:'red'}}>Please enter phone number</span>:null}

        <br/>
        <div className='text-vsm' style={{marginTop:20,fontWeight:500}}>What can we help you with ?</div>
        
        <label className="col-12">
        <input type="radio" 
        name="help" 
        style={{marginTop:20,marginRight:5}}
        value="build a new product"
        id="build a new product"
        onChange={changeHandler}
        required
        />

          Build a new product
        </label>
        <br/>


        <label className="col-12">
        <input type="radio" 
        name="help" 
        style={{marginTop:20,marginRight:5}}
        value="improve_an_existing_product" 
        id="improve_an_existing_product" 
        onChange={changeHandler}
        required
        />
            Improve an existing product
        </label>
        <br/>

        <label className="col-12">
        <input type="radio" 
        name="help" 
        style={{marginTop:20,marginRight:5}}
        value="help_with_maintaing_a_product" 
        id="help_with_maintaing_a_product"
        onChange={changeHandler}
        />

            Help with maintaining a product
        </label>
        <br/>
        <label className="col-12">
        <input type="radio" 
        name="help" 
        value="Managing_resources" 
        id="Managing_resources" 
        onChange={changeHandler}
        style={{marginTop:20,marginRight:5}}/>

            Managing resources
        </label>
        <br/>


        <label className="col-12">
        <input type="radio" 
        name="help" 
        value="something_else" 
        id="something_else" 
        onChange={changeHandler}
        style={{marginTop:20,marginRight:5}}/>

            Something else
        </label>
        <br/>





        <div className='text-vsm' style={{marginTop:20,fontWeight:500}}>What is your budget ?</div>

        
        <label className="col-12">
        <input type="radio" 
        name="total_budget" 

        value="lessThan $25,000"

        id="lessThan25,000"
        style={{marginTop:20,marginRight:5}}
        onChange={changeHandler2}
        required
        />
            Less than $25,000
        </label>
        <br/>

        <label className="col-12">
        <input type="radio" 
        name="total_budget"
        id="Between25&50"
        style={{marginTop:20,marginRight:5}}

        value="Between $25,000 & $50,000"
        onChange={changeHandler2}

        />

            $25,000 to $50,000
        </label>
        <br/>

        <label className="col-12">
        <input type="radio" 
        name="total_budget"
        style={{marginTop:20,marginRight:5}}
        id="Between50&100"

        value="between $50,000 & $100,000"
        onChange={changeHandler2}
        />

            $50,000 to $100,000
        </label>
        <br/>

        <label className="col-12">
        <input type="radio"
        name="total_budget"
        style={{marginTop:20,marginRight:5}}
        id="Morethan100"
        value="Morethan100"
        onChange={changeHandler2}/>

            More than $100,000
        </label>
        <br/>

        <label className="col-12">
        <input type="radio" 
         name="total_budget"
        style={{marginTop:20,marginRight:5}}
        value="ToBeDecided"
        id="ToBeDecided"
        onChange={changeHandler2}/>

            To be decided
        </label>
        <br/>
        <div className='text-vsm' style={{marginTop:40, fontWeight:500}}>How did you hear about Sunai ?</div>
        <label className='col-12 form_label'>

        <input type="text" 
        name="hear-about-us" 
        value={values.hearAboutUs}
        className='col-sm-12 col-form-label col-form-label-lg' 
        onChange={hearAboutUschangeHandler}
        style={{marginBottom:10}}
        required/> 
        </label>
          <br/> 
        {submitted && !values.hearAboutUs?<span style={{color:'red'}}>Where did you hear about us</span>:null}
        <br/>
        <ReCAPTCHA
        sitekey={secretKey }
        ref={reRef}
        style={{marginBottom:20}}
      /> 
        
        <div className='btn-text' style={widthb<451?style1:style2}>
        <Button buttonText='Submit' style={{marginTop:20}}/>
        <ToastContainer />
        
        </div>
      </form>
      </div>
      <div className='col-lg-6 col-md-12 col-sm-12 relative-rem-text'>
        <RemainingTexts />
      </div>
      </div>

    </div>
    </>
  );
}

export default Forms
